//import { ACTIONS, generateUrl } from '../utils/urls';
import { ROLES } from '@food/auth';

const baseUrlProd = 'https://api.italianfood.net';
const baseUrlDev = 'https://api2.italianfood.net';

export const CONFIG = {
	'vote.prodottofood.it': {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-21094558-9',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		LOGO: '/imgs/prodotto-food-generic.png',
		LANG: 'it_IT',
		HELP_EMAIL: 'prodottofood@gruppofood.com',
		AUTH_ROLES: ['BUYER_PL_ITALIA', 'PRODUCER'],
	},
	'awards.italianfood.net': {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@italianfood.net',
		REQUIRES_AUTH: true,
		AUTH_ROLES: [ROLES.BUYER],
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/vote/imgs/italianfood-generic.svg',
		LANG: 'en_US',
		LAYOUT_VOTING: 'custom01'
	},
	'vote.italianfood.net': {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@italianfood.net',
		REQUIRES_AUTH: true,
		AUTH_ROLES: [ROLES.BUYER],
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/imgs/italianfood-generic.svg',
		LANG: 'en_US',
	},
	'stage-vote.prodottofood.it': {
		HOST: baseUrlDev,
		GRAPHQL_API: baseUrlDev + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlDev + '/api/authenticate',
			VERIFY: baseUrlDev + '/api/verify',
			TRACKING: baseUrlDev + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@italianfood.net',
		REQUIRES_AUTH: true,
		AUTH_ROLES: [ROLES.BUYER, ROLES.PRODUCER],
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/imgs/prodotto-food-generic.png',
		LANG: 'en_US',
	},
	'vote.dolcesalato.com': {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@gruppofood.com',
		REQUIRES_AUTH: true,
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/imgs/dolcesalato-generic.svg',
		LANG: 'it_IT',
		AUTH_ROLES: [],
	},
	localhost: {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@italianfood.net',
		REQUIRES_AUTH: true,
		AUTH_ROLES: [ROLES.BUYER],
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/vote/imgs/italianfood-generic.svg',
		LANG: 'en_US',
		LAYOUT_VOTING: 'custom01'
	},
};

export function config(key) {
	return CONFIG[window.location.hostname][key];
}
