import createBrowserHistory from 'history/createBrowserHistory';
import { config } from '../static/config';
import ReactGA from 'react-ga';

const history = createBrowserHistory({ basename: '/vote' }); // per awards.italianfood.net/vote/: { basename: '/vote' }
history.listen((location) => {
	if (config('UA_CODE')) {
		ReactGA.set({ page: window.location.pathname + window.location.search });
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
});

export { history };
