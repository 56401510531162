import React, { Component } from 'react';
import RatingBlock from './RatingBlock';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import {
	Button,
	Dimmer,
	Form,
	Grid,
	GridColumn,
	Header,
	Icon,
	Segment,
	Progress
} from 'semantic-ui-react';
import styled from 'styled-components';
import { queryErrorHandler } from '../utils/errorHandlers';
import Award from './Award';
import { getFirstBy } from '../utils/generic';
import t from '../utils/labels';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { GenericSpinner } from './GenericSpinner';
import { ACTIONS, generateUrl } from '../utils/urls';
import { UserData } from '@food/auth';
import * as Sentry from '@sentry/browser';
import { client } from '../utils/client';
import { config } from '../static/config';

export const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${props => props.color || '#888'} !important;
  }
`;

const candidacyRateFragment = gql`
	fragment CandidacyRateFragment on Candidacy {
		id
		name
		description1
		description2
		description3
		description4
		description5
		description6
		description7
		description8
		launchDate
		goods {
			id
			shelfLifeLength
			nameIta
			ingredientsImageMedia {
				id
				mediumThumbUrl
				largeThumbUrl
				smallThumbUrl
				origUrl
				name
			}
			ingredientsImageItaMedia {
				id
				mediumThumbUrl
				largeThumbUrl
				smallThumbUrl
				origUrl
				name
			}
		}
		Company {
			id
			name
			logoMedia {
				smallThumbUrl
			}
		}
		Award {
			id
			ratingOverallLabel
			rating1Label
			rating2Label
			rating3Label
			rating4Label
			rating5Label
			description1Label
			description2Label
			description3Label
			description4Label
			description5Label
			description6Label
			description7Label
			description8Label
			 langCode
		}
		featuredImageMedia {
			id
			mediumThumbUrl
			largeThumbUrl
			smallThumbUrl
			origUrl
			name
		}
		candidacyRatings {
			id
		}
		AwardCategory {
			id
			giftUrl
			color
			candidacies {
				id
				localId
				Company {
					id
				}
			}
		}
	}
`;

// query
const AwardCategoryRatingsQuery = gql`
	query($id: ID!) {
		category: node(id: $id) {
			... on AwardCategory {
				id
				name
				giftUrl
				color
				Award {
					id
				}
			}
		}
		candidacies: awardCategoryCandidaciesToRate(AwardCategory: { id: $id }) {
			id
			...CandidacyRateFragment
		}
	}
	${candidacyRateFragment}
`;

const specificCandidacyRateQuery = gql`
	query SpecificCandidacyRateQuery($id: ID!) {
		node(id: $id) {
			id
			...CandidacyRateFragment
			... on Candidacy {
				candidacyRatings {
					id
				}
			}
		}
	}
	${candidacyRateFragment}
`;

// mutation
const RateCandidacy = gql`
	mutation RateCandidacy($values: CandidacyRatingCreateInput!) {
		candidacyRatingCreate(values: $values) {
			id
			localId
			Candidacy {
				localId
				id
			}
		}
	}
`;

// component
class AwardCategoryRateView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			leaveModalOpen: false,
			nextLocation: null,
			nextSearch: null,
			forcedRedirect: false,
			error: null,
		};
	}

	componentDidMount() {
		const MAGIC_NUMBER = 6;
		const { history, categoryId } = this.props;

		console.log("AwardCategoryRateView componentDidMount",this.props);

		this.unblock = history.block((nextLocation) => {
			const { forcedRedirect } = this.state;

			try {
				const oldData = client.readQuery({
					query: AwardCategoryRatingsQuery,
					variables: { id: categoryId },
				});
				const { candidacies } = oldData;
				// se il path successivo è "login" significa che sono incappato in un errore di sessione scaduta
				// quindi il redirect deve essere effettuato, per non creare un loop di redirect
				if (
					forcedRedirect ||
					!candidacies ||
					candidacies.length > MAGIC_NUMBER ||
					nextLocation.pathname === '/login' ||
					nextLocation.pathname === '/logout'
				) {
					console.log("errore in awardcategoryratereview unblock");
					return true;
				} else {
					this.setState({
						leaveModalOpen: true,
						nextLocation,
					});
					return false;
				}
			} catch (e) {
				// significa che non ho in cache locale la query usata per ottenere le candidature in modo casuale
				// significa, quindi che sono in un caso di candidatura specifica
				// non devo fare niente!
			}
			return true;
		});
	}

	componentWillUnmount() {
		this.unblock();
	}

	_redirect = (location) => () =>
		this.setState({
			forcedRedirect: true,
			nextLocation: location,
		});

	_rate = (user, award, category, candidacy) => async (ratings) => {
		const { candidacyId, location, history } = this.props;
		const isSpecific = Boolean(candidacyId);

		const mutationArguments = {
			User: { id: user.id },
			Candidacy: { id: candidacy.id },
			overall: ratings[0] || null,
			rating1: ratings[1] || null,
			rating2: ratings[2] || null,
			rating3: ratings[3] || null,
			rating4: ratings[4] || null,
			rating5: ratings[5] || null,
			askSample: false,
		};

		try {
			window.scrollTo(0, 0);
			const mutationResult = await client.mutate({
				mutation: RateCandidacy,
				variables: { values: mutationArguments },
				update: (store, props) => {
					// faccio un update della cache di apollo a mano poiche', per come sono strutturati i dati, non riesce
					// ad aggiornarsi da sola la query fatta per la dashboard con tutte le categorie
					try {
						const newRating = props.data.candidacyRatingCreate;
						let data = store.readQuery({
							query: Award.query,
							variables: { id: award.id },
						});
						const c = getFirstBy(
							data.award.categories,
							'id',
							category.id,
						);
						c.candidacyRatings.push(newRating);
						store.writeQuery({
							query: Award.query,
							variables: { id: award.id },
							data,
						});
					} catch (e) {
						// la query della dashboard non e' ancora stata eseguita quindi non e' in cache
						// non devo fare niente!
					}

					try {
						const newRating = props.data.candidacyRatingCreate;
						const candidacyId = newRating.Candidacy.id;
						let categoryData = store.readQuery({
							query: AwardCategoryRatingsQuery,
							variables: {
								id: category.id,
							},
						});
						categoryData.candidacies = categoryData.candidacies.filter(
							(c) => c.id !== candidacyId,
						);
						console.log(newRating, candidacyId, categoryData.candidacies)
						
						store.writeQuery({
							query: AwardCategoryRatingsQuery,
							variables: {
								id: category.id,
							},
							data: categoryData,
						});
					} catch (e) {
						// la query della dashboard non e' ancora stata eseguita quindi non e' in cache
						// non devo fare niente!
					}
				},
			});
			if (mutationResult) {
				// siccome si possono avere url a specifici candidature, nel caso ci si trovi con uno di essi devo
				// reimpostare l'url in modo corretto
				if (isSpecific) {
					const genericLocation = location.pathname.split('/');
					genericLocation.pop();
					history.push(genericLocation.join('/'));
				}

				return true;
			} else {
				queryErrorHandler(mutationResult);
			}
		} catch (e) {
			/*if (
				e &&
				e.graphQLErrors &&
				e.graphQLErrors.length > 0 &&
				e.graphQLErrors[0].state &&
				e.graphQLErrors[0].state[0] &&
				e.graphQLErrors[0].state[0].type === 'MUST_BE_UNIQUE'
			) {
				window.location.reload();
				
				// non faccio niente!
			}*/

			queryErrorHandler(e);
			this.setState({ error: e });
		}
	};

	_forceRedirect = () => this.setState({ forcedRedirect: true });

	_hideDimmer = () => this.setState({ leaveModalOpen: false, error: null });

	render() {
		const {
			// data: { loading, error, candidacies, category },
			awardId,
			categoryId,
			candidacyId,
			location,
		} = this.props;
		const {
			leaveModalOpen,
			nextLocation,
			forcedRedirect,
			error: stateError,
		} = this.state;

		if (forcedRedirect) return <Redirect to={nextLocation} />;

		const isSpecific = Boolean(candidacyId);

		return (
			<Query
				query={
					isSpecific
						? specificCandidacyRateQuery
						: AwardCategoryRatingsQuery
				}
				variables={{ id: isSpecific ? candidacyId : categoryId }}
			>
				{({ error, loading, data }) => {
					
					if (loading) return <GenericSpinner />;
					if (error) return queryErrorHandler(error);
					
					// a seconda del risultato della query posso capire se si tratta di una candidatura specifica o di una
					// casuale passatami dal server. Potrei dedurlo anche dall'url, ma preferisco questa via
					let { candidacies, category, node } = data;
					let candidate = node || candidacies[0];

					if (!candidacies) {
						candidacies = [candidate];
						category = candidate.AwardCategory;
					}

					if (
						candidate &&
						candidate.candidacyRatings &&
						candidate.candidacyRatings.length > 0
					) {
						// sono nel caso in cui sono tornato ad un link specifico ma ho gia' votato questa candidatura
						// rimando al caso generale
						let url = '/';
						if (isSpecific) {
							const genericLocation = location.pathname.split('/');
							genericLocation.pop();
							url = genericLocation.join('/');
						}
						return <Redirect to={url} />;
					}

					if (!candidate) {
						return (
							<Dimmer active={true} page>
								<Header as="h2" icon inverted>
									<Icon name="heart" />
									{t`thanks for your ratings!`}
									<Header.Subheader>{t`now go elsewhere`}</Header.Subheader>
								</Header>
								<Form onSubmit={(e) => e.preventDefault()}>
									<Button
										basic
										color="red"
										onClick={this._redirect(generateUrl(
											'Award',
											ACTIONS.DETAIL,
											awardId,
										))}
									>
										<Icon name="home" /> {t`return dashboard`}
									</Button>
									<Button
										basic
										color="green"
										onClick={this._redirect(
											[
												generateUrl(
													'Award',
													ACTIONS.DETAIL,
													awardId,
												),
												generateUrl(
													'AwardCategory',
													ACTIONS.DETAIL,
													category.id,
												),
											].join(''),
										)}
									>
										{t`see results`} <Icon name="right arrow" />
									</Button>
									{category.giftUrl && (
										<div>
											<Button positive floated="right" onClick={(e) => {window.open(category.giftUrl, "_blank");}} style={{ float:'none', marginLeft: '0', marginTop: '0.25em' }}>
												{t`download PDF`}
											</Button>
										</div>
									)}
								</Form>
							</Dimmer>
						);
					} else {
						return (
							<UserData>
								{({ userData, managedCompany }) => {
									const disabled =
										managedCompany &&
										managedCompany.Company &&
										candidacies.some(
											(c) =>
												c.Company.id === managedCompany.Company.id,
										);

									if (disabled) {
										// Sono nel caso in cui un producer sta provando a votare nella sua categoria
										// non dovrebbe avere accesso a questa pagina, ma si sa mai.. tramite il link di un amcio..
										// semplicemente lo blocco, ma segnalo il misfatto
										Sentry.withScope((scope) => {
											scope.setExtra('user data', userData);
											scope.setExtra('category', category);
											Sentry.captureException(
												new Error(
													'Producer entrato a votare nella sua stessa categoria',
												),
											);
										});

										return (
											<Segment padded>
												<Header as="h2">
													{t`category vote forbidden title`}
												</Header>
												<p
													dangerouslySetInnerHTML={{
														__html: t`category vote forbidden html`,
													}}
												/>
												<Button
													as={Link}
													to={'/'}
													icon={'angle right'}
													labelPosition={'right'}
													content={t`return to home`}
												/>
											</Segment>
										);
									} else {
										// qui tutto e' ok, posso finalmente mostrare la pagina di voto

										const totalCategories = candidate.AwardCategory.candidacies.length;
										const leftCategories = candidacies.length;
										const ratedCategories = totalCategories - leftCategories;
	
										return (
											<section data-entity="award-category">
												{/* modale mostrata solo nel caso in cui si stia cercando di uscire con meno di TOT prodotti da votare */}
												<Dimmer active={leaveModalOpen} page>
													<Header as="h2" icon inverted>
														<Icon name="warning sign" />
														{t`few products remaining`}
														<Header.Subheader>{t`few products remaining description`}</Header.Subheader>
													</Header>
													<Form>
														<Button
															basic
															color="green"
															onClick={this._hideDimmer}
														>
															<Icon name="close" />
															{t`stay on this category`}
														</Button>
														<Button
															basic
															color="red"
															onClick={this._forceRedirect}
														>
															{t`leave page`}{' '}
															<Icon
																name="share"
																className="margin-left"
															/>
														</Button>
													</Form>
												</Dimmer>

												{/* modale mostrata solo se la comunicazione al server del voto non e' andata a buon fine */}
												<Dimmer active={stateError} page>
													<Header as="h2" icon inverted>
														<Icon name="warning sign" />
														{t`voting error`}
														<Header.Subheader>
															<div
																dangerouslySetInnerHTML={{
																	__html: t`voting error description html`,
																}}
															/>
														</Header.Subheader>
													</Header>
													<Form>
														<Button
															color="grey"
															as={'a'}
															href={
																'mailto:' + config('HELP_EMAIL')
															}
														>
															<Icon name="envelope" />
															{t`Contact us`}
														</Button>
														<Button
															color="green"
															onClick={this._hideDimmer}
														>
															<Icon name="redo" />
															{t`retry`}
														</Button>
													</Form>
												</Dimmer>

												{/* la parte effettiva di voto */}
												<Grid>
													<GridColumn
														computer="16"
														tablet="16"
														mobile="16"
														widescreen="12"
														style={{paddingBottom: 0}}
													>
														<StyledProgressBar value={ratedCategories} total={totalCategories} progress='ratio' color={category.color} style={{margin: 0}} />
													</GridColumn>
													<GridColumn
														computer="16"
														tablet="16"
														mobile="16"
														widescreen="12"
													>
														<RatingBlock
															candidate={candidate}
															onConfirmRatings={this._rate(
																userData,
																candidate.Award,
																candidate.AwardCategory,
																candidate,
															)}
														/>
													</GridColumn>
												</Grid>
											</section>
										);
									}
								}}
							</UserData>
						);
					}
				}}
			</Query>
		);
	}
}

export default withRouter(AwardCategoryRateView);
